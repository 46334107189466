$duration: 1.5s;
$dimension: 1.2em;

/**
 * Create the loop delay with
 * the extra keyframes
 */
@keyframes moveup {
  0%,
  60%,
  100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(1em);
  }
}

@keyframes movedown {
  0%,
  60%,
  100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(-1em);
  }
}

.nova-loader-container {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 40px;

  &.static-loader {
    position: static;
    transform: none;
    margin: 10px auto;
  }

  .loader-layer {
    display: block;
    position: absolute;
    height: $dimension;
    width: $dimension;
    box-shadow: 2px 2px 1px rgba(20, 20, 20, 0.4);
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg);

    &:nth-of-type(1) {
      background: #62ab35;
      margin-top: $dimension/2;
      animation: movedown $duration cubic-bezier(0.39, 0.575, 0.565, 1) $duration/2 infinite normal;
      &:before {
        content: '';
        position: absolute;
        width: 85%;
        height: 85%;
        background: #5c9649;
      }
    }

    &:nth-of-type(2) {
      background: #7acd45;
      margin-top: $dimension/4;
    }

    &:nth-of-type(3) {
      background: #89da56b7;
      animation: moveup $duration cubic-bezier(0.39, 0.575, 0.565, 1) infinite normal;
    }
  }
}
